import {ArtEntryDto} from "./model/dto";
import RestService, {ErrorDetails, ResponseStatus, RestCall} from "./restService";

async function getGalleryData(
    onSuccess: (a: ResponseStatus<ArtEntryDto[]>) => Promise<any>,
    onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    await RestService.doUnauthenticatedCall({
            url: "https://artportfoliofunction-o3qyavvdxq-oa.a.run.app" + "/portfolio/art",
            method: "get"
        } as RestCall,
        onSuccess,
        onFailure)
}



const GalleryApiService = {
    getGalleryData
}
export default GalleryApiService;
