import './galleryStrip.css';
import {Component} from "react";
import * as React from "react";
import PageSection from "../structural/pageSection";
import {ArtEntryDto} from "../../service/model/dto";
import GalleryApiService from "../../service/galleryApiService";
import Button from "../structural/button";
import {ErrorDetails, RestCall} from "../../service/restService";

interface GalleryStripState {
    art: ArtEntryDto[]
    shownModal: number,
}

class GalleryStrip extends Component<{}, GalleryStripState> {

    constructor(props: {}) {
        super(props, {})
        this.state = {
            art: [],
            shownModal: -1
        };

        this.handleOverlayClick = this.handleOverlayClick.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }



    async componentDidMount() {
        GalleryApiService.getGalleryData(async a => {
                this.setState({
                    art: a.body
                })
            },
            async (r: RestCall, e: ErrorDetails) => {
                console.error(e.error, e.response?.statusCode, e.response?.body);
            });
        document.addEventListener('keydown', this.handleKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    handleKeyDown = (event : any) => {
        if (event.key === "Escape") {
            this.handleCloseModal();
        }
    }

    handleOverlayClick(i : number) {
        this.setState({
            shownModal: i
        });
    };

    handleCloseModal(){
        if (this.state.shownModal != -1) {
            this.setState({
                shownModal: -1
            })
        }
    };


    getOverlayButtonText(art : ArtEntryDto) {
        if (art.sold) {
            return "Sold";
        } else if (art.shopLink) {
            return "For sale!";
        } else {
            return "Available Soon";
        }
    }

    getOverlayButtonStyle(art : ArtEntryDto) {
        if (art.sold) {
            return "overlay-button-sold";
        } else if (art.shopLink) {
            return "overlay-button-available";
        } else {
            return "overlay-button-soon";
        }
    }

    openShopLink(event: any, art: ArtEntryDto) {
        event.stopPropagation();
        window.open(art.shopLink, "_blank");
    }


    render() {

        // TODO images should be no more than 1920 wide and 1728 high
        // TODO tile images should be no more than 768 wide and 640 high

        // TODO on click of image opens full screen view of the image and details with buy link
        // tODO fix modal content
        return (
            <PageSection id={"GalleryStrip"}>
                <div className={"Gallery-Strip"}>
                    <div key={"Gallery-Title"} className={"Gallery-Title"}>Gallery</div>
                    <div key={"Gallery-Grid"} className={"Gallery-Grid"}>
                        {this.state.art.map((art, i) => (
                            <div className={"Gallery-Grid-Entry"} key={"Art-" + i}>
                                <div className={"Gallery-Image-Wrapper"}
                                     style={{backgroundImage: "url(" + art.images.preview + ")"}}>
                                    <div className="overlay" onClick={() => this.handleOverlayClick(i)}>
                                        <div className="overlay-content">
                                            <div>{art.name}</div>
                                            <div onClick={(event) => { art.shopLink  && this.openShopLink(event, art); }} className={this.getOverlayButtonStyle(art)}>
                                                {this.getOverlayButtonText(art)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {this.state.shownModal > -1 && (
                        <div className="modal">
                            <div className="modal-content">
                                <span className="close" onClick={this.handleCloseModal}>&times;</span>
                                <div className={"Modal-Data"}>
                                    <div className={"Gallery-Image-Wrapper-Full"} key={"Gallery-Image-Wrapper-Full"}
                                         style={{backgroundImage: "url(" + this.state.art[this.state.shownModal].images.full + ")"}}/>
                                    <div className={"Gallery-Modal-Data-Wrapper"} key={"Gallery-Modal-Data-Wrapper"}>
                                        <div className={"Gallery-Modal-Data-Title"} key={"Gallery-Modal-Data-Title"}>{this.state.art[this.state.shownModal].name}</div>
                                        {this.state.art[this.state.shownModal].description && <div className={"Gallery-Modal-Data-Description"} key={"Gallery-Modal-Data-Description"}>{this.state.art[this.state.shownModal].description}</div>}
                                        <div className={"Gallery-Modal-Data-Dimensions"} key={"Gallery-Modal-Data-Dimensions"}>
                                            <div key={"Gallery-Modal-Width"}>Width: {this.state.art[this.state.shownModal].dimensions.width}</div>
                                            <div key={"Gallery-Modal-Height"}>Height: {this.state.art[this.state.shownModal].dimensions.height}</div>
                                            <div key={"Gallery-Modal-Depth"}>Depth: {this.state.art[this.state.shownModal].dimensions.depth}</div>
                                        </div>
                                        <div className={"Gallery-Modal-Data-Button-Wrapper"} key={"Gallery-Modal-Data-Button-Wrapper"}>
                                            <Button value={this.state.art[this.state.shownModal].sold ? "Sold" : this.state.art[this.state.shownModal].shopLink ? "Buy Now" : "Available Soon"}
                                                    onClick={() => window.open(this.state.art[this.state.shownModal].shopLink, '_blank')}
                                                    enabled={!this.state.art[this.state.shownModal].sold && !!this.state.art[this.state.shownModal].shopLink} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <style>{'body { overflow: hidden; }'}</style>
                        </div>
                    )}
                </div>
            </PageSection>
        );
    }
}

export default GalleryStrip;