import RestService, {ErrorDetails, ResponseStatus, RestCall} from "./restService";

// eslint-disable-next-line require-jsdoc
async function sendContactFormMessage(name: string,
                                      email: string,
                                      message: string,
                                      onSuccess: (a: ResponseStatus<String>) => Promise<any>,
                                      onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    await RestService.doUnauthenticatedCall({
            url: "https://artportfoliofunction-o3qyavvdxq-oa.a.run.app" + "/contact/requests",
            method: "post",
            body: {
                name: name,
                email: email,
                message: message
            },
        } as RestCall,
        onSuccess,
        onFailure);
}
const EmailService = {
    sendContactFormMessage,
};

export default EmailService;

