import * as  React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyC4XRY7E711A1hdR4jZSUZPYnWM_8Dg_gs",
    authDomain: "curriculum-vitae-397e9.firebaseapp.com",
    projectId: "curriculum-vitae-397e9",
    storageBucket: "curriculum-vitae-397e9.appspot.com",
    messagingSenderId: "760070500368",
    appId: "1:760070500368:web:ff18f91976899aa6c29a95",
    measurementId: "G-59G5SZ7M6Y"
};

firebase.initializeApp(firebaseConfig);


ReactDOM.render(
    <div className="bodyWrapper">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


