import './contactSection.css';
import {Component} from "react";
import * as React from "react";
import PageSection from "../structural/pageSection";
import Emails from "../../service/emails";
import Button from "../structural/button";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

interface ContactStripState {
    name: string,
    email: string,
    message: string
    messageSent: boolean,
    captchaPassed: boolean,
    contactEnabled: boolean
}

class ContactStrip extends Component<{}, ContactStripState> {

    static captchaKey = '6LcAGbIaAAAAAL_nI_zsW1tjOMCNS7UttzsgSsxA';


    constructor(props: {}) {
        super(props, {})
        this.state = {
            name: "",
            email: "",
            message: "",
            captchaPassed: false,
            messageSent: false,
            contactEnabled: true,
        }
        this.handleNameUpdate = this.handleNameUpdate.bind(this);
        this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
        this.handleMessageUpdate = this.handleMessageUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.consumeCaptchaToken = this.consumeCaptchaToken.bind(this);
        this.captchaVerified = this.captchaVerified.bind(this);
    }

    handleNameUpdate(event: any) {
        this.setState({
            name: event.target.value
        })
    }

    handleEmailUpdate(event: any) {
        this.setState({
            email: event.target.value
        })
    }

    handleMessageUpdate(event: any) {
        this.setState({
            message: event.target.value
        })
    }

    async handleSubmit(event: any) {
        this.setState({
            contactEnabled: false
        })
        // Prevent page reload
        event.preventDefault();
        await Emails.sendContactFormMessage(this.state.name, this.state.email, this.state.message,
            async (s) => {
                this.setState({
                    messageSent: true
                });
            },
            async (r, e) => {
                console.error(e);
            });

    }



    captchaVerified(data : any) {
        this.setState({
            captchaPassed: true,
        })
    }

    consumeCaptchaToken(token: string) {
        fetch('https://api.ipify.org/')
            .then(response => response.text())
            .then(b => {
                fetch ('https://captchacheckfunction-o3qyavvdxq-oa.a.run.app/capthcacheck', {
                    method: 'post',
                    body: JSON.stringify({token:token, userip: b}),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(this.captchaVerified)
            })

    }


    renderCaptcha() {
        return <div className={"ContactSectionCaptchaWrapper"} key={"ContactSectionCaptchaWrapper"}>
            <div className={"ContactSectionCaptchaText"} key={"ContactSectionCaptchaText"}>Making sure you're not a robot...</div>
            <div className={"ContactSectionCaptchaProvider"} key={"ContactSectionCaptchaProvider"}>
                <GoogleReCaptchaProvider reCaptchaKey={ContactStrip.captchaKey}>
                    <GoogleReCaptcha onVerify={this.consumeCaptchaToken}/>
                </GoogleReCaptchaProvider>
            </div>
        </div>;
    }

    renderContactForm() {
        return (<form>
            <div className={"ContactForm"}>
                <input className={"ContactFormEntry"} type="text" name="Name" required placeholder="Name" onChange={this.handleNameUpdate} value={this.state.name}/>
                <input className={"ContactFormEntry"} type="text" name="Email" required placeholder="Email" onChange={this.handleEmailUpdate} value={this.state.email}/>
                <textarea className={"ContactFormEntry"} name="Message" required placeholder="Your Message" onChange={this.handleMessageUpdate} value={this.state.message} rows={5}/>
                <Button value={"Send"} onClick={this.handleSubmit} enabled={this.state.contactEnabled} />
            </div>
        </form>);
    }

    renderConfirmDialog() {
        return <div className={"ContactSectionConfirmMessage"}>Message Submitted Successfully</div>
    }

    render() {
        return (
            <PageSection id={"ContactStrip"}>
                <div className={"ContactStrip"}>
                    <div className={"ContactTitle"}>Contact Me</div>
                    {this.state.messageSent ? this.renderConfirmDialog() : this.state.captchaPassed ? this.renderContactForm() : this.renderCaptcha() }
                </div>
            </PageSection>
        );
    }
}

export default ContactStrip;