export interface RestCall {
    url: string,
    method: string,
    body?: any,
    headers: any,
}

export interface ErrorDetails {
    error: string,
    response?: ResponseStatus<string>
}

export interface ResponseStatus<T> {
    ok: boolean
    statusCode: number,
    body: T
}

async function doUnauthenticatedCall<T>(restCall: RestCall,
                                        onSuccess: (a: ResponseStatus<T>) => Promise<any>,
                                        onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    try {
        fetch(restCall.url,
            {
                method: restCall.method,
                body: restCall.body ? JSON.stringify(restCall.body) : undefined,
                headers: buildBasicHeadersObject(restCall)
            })
            .then(parseResponseBody)
            .then(r => handleResponse(r, restCall, onSuccess, onFailure))
            .catch(e => handleFailure(e, restCall, onFailure));
    } catch (e) {
        await handleFailure(e, restCall, onFailure);
    }
}

async function handleFailure(e: any,
                             restCall: RestCall,
                             onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    console.log(e);
    await onFailure(restCall, {
        error: (typeof e === "string") ? e : e.message,

    })
}

async function parseResponseBody<T>(response: any): Promise<ResponseStatus<T>> {
    const body = response.ok ? (response.status === 204 || response.status === 202) ? undefined : await response.json() : await response.text()
    return {
        ok: response.ok,
        body: body,
        statusCode: response.status,
    };
}

async function handleResponse<T>(response: ResponseStatus<any>, restCall: RestCall,
                                 onSuccess: (a: ResponseStatus<T>) => Promise<any>,
                                 onFailure: (restCall: RestCall, error: ErrorDetails) => Promise<any>) {
    if (response.ok) {
        await onSuccess(response);
    } else {
        await onFailure(restCall, {
            response: response,
            error: response.body
        });
    }
}

function buildBasicHeadersObject(restCall: RestCall, jwtToken?: string): { [key: string]: string } {
    let headers: { [key: string]: string } = {};
    if (restCall.headers) {
        headers = {...restCall.headers};
    }
    if (restCall.body) {
        headers["Content-Type"] = "application/json";
    }
    if (jwtToken) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
    }
    return headers;
}

const RestService = {
    doUnauthenticatedCall
}
export default RestService;
