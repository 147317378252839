import './pageSection.css';
import {Component} from "react";
import * as React from "react";

interface PageSectionProps {
    id: string
}

class PageSection extends Component<PageSectionProps, {}> {

    constructor(props: PageSectionProps) {
        super(props, {})
    }


    render() {
        return (
            <div className="PageSection-Wrapper" ref={"PageSection-"+this.props.id}>
                {this.props.children}
            </div>);
    }
}

export default PageSection;