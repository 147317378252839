import {useEffect} from 'react';
import * as React from 'react';
import './App.css';
import CookieConsent, {Cookies, getCookieConsentValue,} from "react-cookie-consent";
import firebase from "firebase/app";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import WelcomeBanner from "./components/data/welcomeBanner";
import GalleryStrip from "./components/data/galleryStrip";
import ContactSection from "./components/data/contactSection";

function App() {

    const handleAcceptCookie = () => {
        firebase.analytics();
    };

    const handleDeclineCookie = () => {
        // remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid")
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    // @ts-ignore
    return (
        <div className="dark-theme App-wrapper">
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<div>
                            <WelcomeBanner/>
                            <GalleryStrip/>
                            <ContactSection/>
                            </div>}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <script src="/__/firebase/8.4.1/firebase-app.js"/>
            <script src="/__/firebase/init.js"/>
        </div>

    );
}

export default App;
