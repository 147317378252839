import './welcomeBanner.css';
import React, {Component, createRef, RefObject, useRef} from "react";
import PageSection from "../structural/pageSection";

class WelcomeBanner extends Component<{}, {}> {

    static numBubbles : number = 10;
    private bubbleContainerRef: RefObject<HTMLDivElement>;
    private bubbleInterval: any; // Store interval reference
    private bubbleCleanupList: number[] = [];
    private bubbleCleanupInterval: any; // Store interval reference
    private bubbleCounter = 0;

    constructor(props: {}) {
        super(props, {})
        this.bubbleContainerRef = createRef();
    }

    componentDidMount() {
        this.createBubbles(); // Initial bubbles
        this.bubbleInterval = setInterval(() => {
            this.createBubbles(); // Create more bubbles at regular intervals
        }, 500); // Add bubbles every 0.5 second
        this.bubbleCleanupInterval = setInterval(() => {
            // Get viewport height
            const viewportHeight = window.innerHeight;

            // Remove bubbles out of view
            this.bubbleCleanupList.forEach(bubbleId => {
                const elementToRemove = document.getElementById("Bubble-" + bubbleId);
                if (elementToRemove) {
                    const bubbleRect = elementToRemove.getBoundingClientRect();
                    if (bubbleRect.bottom < 0 || bubbleRect.top > viewportHeight) {
                        elementToRemove.parentNode?.removeChild(elementToRemove);
                        this.bubbleCleanupList.splice(this.bubbleCleanupList.indexOf(bubbleId), 1);
                    }
                }
            });
        }, 2000); // Clean up every 2 seconds
    }

    componentWillUnmount() {
        clearInterval(this.bubbleInterval); // Clear interval to prevent memory leaks
        clearInterval(this.bubbleCleanupInterval);
    }

    createBubbles() {
        if (this.bubbleContainerRef.current) {
            const bubbleCount = Math.random() * WelcomeBanner.numBubbles +1;
            for (let i = 0; i < bubbleCount; i++) {
                const bubble = document.createElement("div");
                bubble.id="Bubble-"+this.bubbleCounter;
                bubble.classList.add("WelcomeBannerBubble");
                bubble.style.left = `${Math.random() * 100}%`;
                bubble.style.width = `${Math.random() * 3 + 0.5}vw`;
                bubble.style.height = bubble.style.width;
                const animationDuration = Math.random() * 5 + 6;
                bubble.style.animationDuration = `${animationDuration}s`; // Randomize animation duration
                this.bubbleContainerRef.current.appendChild(bubble);
                this.bubbleCleanupList.push(this.bubbleCounter);
                if (this.bubbleCounter > 100000) {  // prevent eventual overflows
                    this.bubbleCounter = 0;
                } else {
                    this.bubbleCounter++;
                }
            }
        }
    }

    render() {
        return (
            <PageSection id={"WelcomeBanner"}>
                <div className={"WelcomeBanner"}>
                    <div ref={this.bubbleContainerRef} className="WelcomeBannerBubbles"></div>
                    <div className="WelcomeBannerLogo">
                        <svg
                            width="240"
                            height="120"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 240 120" // Set viewBox to control SVG scaling
                            preserveAspectRatio="xMidYMid meet" // Center SVG content and maintain aspect ratio
                            strokeOpacity="0.3"
                        >
                            <path
                                d="M10,110 L60,10 L80,10 C 80,10 175,-10 110,110 L160,10 L180,10 L230,110 L210,110 L175,30 L165,30 L130,110 L130,110 L110,110 L75,30 L65,30 L30,110 L10,110 L60,10"
                                stroke="#fff"
                                strokeWidth="4"
                                fill="none"
                            />
                        </svg>
                    </div>
                    <div className={"WelcomeBannerTitle"}>Aaron Axisa Art</div>
                </div>
            </PageSection>
        );
    }
}

export default WelcomeBanner;